<template>
  <div class="container-checkout-payment">
    <div v-if="!dataCart.carrito_bono_codigo" class="container-bono-regalo">
      <div class="w-100 d-flex mb-3">
        <button
          class="bnt-outline btn-bono-regalo-checkout"
          @click="showBonoRegalo = !showBonoRegalo"
        >
          {{
            showBonoRegalo
              ? 'Cerrar ventana'
              : '¿Tienes un saldo a favor / bono de regalo?'
          }}
        </button>
      </div>
      <div v-if="showBonoRegalo" class="container-data-bono-regalo">
        <div
          class="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-4"
        >
          <div class="d-flex align-items-center">
            <img
              src="@/assets/icons/checkout/icon-checkout-5.svg"
              style="min-width: 22px"
            />
            <p class="mb-0 ps-3" style="font-size: 18px">
              Saldo a favor / Bono de regalo
            </p>
          </div>
        </div>
        <div class="mb-4">
          <p>
            Ingresa el código de tu <b>saldo a favor / bono regalo.</b> Recuerda
            que si tu compra es superior al valor del <b>saldo a favor</b>,
            debes usar un método de pago adicional.
          </p>
        </div>
        <div class="container-input-group-bono mb-3">
          <div class="input-simple-clover">
            <label
              class="label-input"
              :style="bonoRegalo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Código</label
            >
            <input v-model="bonoRegalo" placeholder="Código" type="text" />
          </div>
          <button class="btn-add-cod bnt-primary" @click="sendBonoRegalo()">
            Verificar
          </button>
        </div>
        <div v-if="errorAddCuponCard">
          <span class="text-error">{{ msgErrorAddCuponCard }}</span>
        </div>
      </div>
    </div>
    <div class="container-methdos-payment">
      <div
        v-if="dataCart.carrito_bono_codigo"
        class="mb-3 container-add-bonoregalo"
      >
        <ul style="list-style: none; padding: 0; margin-bottom: 0.5rem">
          <li class="d-flex">
            <td>
              <p class="mb-0">
                <b>Saldo a favor:</b> {{ dataCart.carrito_bono_codigo }}
              </p>
            </td>
            <td class="ms-auto">
              <p class="mb-0">
                <b
                  >${{
                    new Intl.NumberFormat('de-DE').format(
                      dataCart.carrito_bono_valor,
                    )
                  }}</b
                >
              </p>
            </td>
          </li>
        </ul>
        <p>
          El valor del saldo es de ${{
            new Intl.NumberFormat('de-DE').format(dataCart.carrito_bono_valor)
          }}
          y se aplicara a tu compra un valor de ${{
            new Intl.NumberFormat('de-DE').format(
              dataCart.carrito_bono_valor_aplicado,
            )
          }}
        </p>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span
            style="cursor: pointer; text-decoration: underline"
            class="mb-4"
            @click="removeGiftCard()"
          >
            <b>Remover</b>
          </span>
          <button
            v-if="dataCart.carrito_total == 0"
            class="btn-primary mb-4"
            @click="payGifCard()"
          >
            Finalizar compra
          </button>
        </div>
        <div v-if="errorAddCuponCard">
          <span class="text-error">{{ msgErrorAddCuponCard }}</span>
        </div>
      </div>
      <div
        v-if="dataCart.mostrar_aviso_fe"
        class="w-100 alert alert-primary mt-2 d-flex flex-wrap mb-4"
      >
        <div class="container-input-checkbox mb-0">
          <input
            id="input-checkbox-tel"
            v-model="esFELocal"
            class="input-checkbox"
            type="checkbox"
            checkmark="checkmark"
          />
          <span class="checkmark"></span>
          <label for="input-checkbox-tel"
            >¿Desea recibir la <b>factura electrónicamente</b>?</label
          >
        </div>
      </div>
      <!-- Medio de pago cupo -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.cupo == 1 ? '' : 'opacity: .6'"
          :title="
            dataCart.cupo == 1 ? 'Cupo personal' : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-1 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #7633FF">
              <img
                src="@/assets/icons/tipoPago/LogoAnim.gif"
                style="max-width: 60px"
              />
            </div>
            <p class="text-header-acordeon">
              <b>Cupo personal Quac</b> <br />
              <span style="font-size: 14px">
                Paga en hasta 10 cuotas quincenales
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.cupo == 1"
          id="acordeon-1"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-method-cupo />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago Bancolombia -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.bancolombia == 1 ? '' : 'opacity: .6'"
          :title="
            dataCart.bancolombia == 1
              ? 'Bancolombia'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-2 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #2c2a29">
              <img src="@/assets/icons/tipoPago/icon-bancolombia.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Bancolombia</b> <br />
              <span style="font-size: 14px">
                Transfiere desde tu cuenta Bancolombia
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.pse == 1"
          id="acordeon-2"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-bancolombia />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago PSE -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.pse == 1 ? '' : 'opacity: .6'"
          :title="dataCart.pse == 1 ? 'PSE' : 'Medio de pago desactivado'"
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-3 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #004983">
              <img src="@/assets/icons/tipoPago/icon-pse.png" />
            </div>
            <p class="text-header-acordeon">
              <b>PSE</b> <br />
              <span style="font-size: 14px">Transferencia electrónica</span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.pse == 1"
          id="acordeon-3"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-p-s-e />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago Addi -->
      <div v-if="dataCart.addi == 1" class="mb-3">
        <b-card-header
          :style="dataCart.addi == 1 ? '' : 'opacity: .6'"
          :title="dataCart.addi == 1 ? 'Addi' : 'Medio de pago desactivado'"
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-5 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #4e7eff">
              <img src="@/assets/icons/tipoPago/icon-addi.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>PSE o Addi</b> <br />
              <span style="font-size: 14px">
                Paga a cuotas sin interés
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.efectivo == 1"
          id="acordeon-5"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-addi />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago Efecty-Baloto -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.efectivo == 1 ? '' : 'opacity: .6'"
          :title="
            dataCart.efectivo == 1 ? 'Efecty' : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-4 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #ffd919">
              <img src="@/assets/icons/tipoPago/icon-efecty.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Efecty</b> <br />
              <span style="font-size: 14px">
                Paga en efectivo en puntos efecty
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.efectivo == 1"
          id="acordeon-4"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-efecty-baloto />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago sistecredito -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.sistecredito == 1 ? '' : 'opacity: .6'"
          :title="
            dataCart.sistecredito == 1
              ? 'Pago sistecredito'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-7 block class="container-header-acordeon">
            <div class="img-header-acordeon" style="background-color: #43d12e">
              <img src="@/assets/icons/tipoPago/icon-sistecredito.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Sistecrédito</b>
              <br />
              <span style="font-size: 14px">
                Paga a cuotas con Sistecrédito
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.sistecredito == 1"
          id="acordeon-7"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-sistecredito />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago Tarjeta de credito -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.tarjeta_credito == 1 ? '' : 'opacity: .6'"
          :title="
            dataCart.tarjeta_credito == 1
              ? 'Tarjeta crédito / débito'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-6 block class="container-header-acordeon">
            <div class="img-header-acordeon">
              <img src="@/assets/icons/tipoPago/icon-tarjeta.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Tarjeta crédito / débito</b> <br />
              <span style="font-size: 14px">
                Recibimos todas las tarjetas
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.tarjeta_credito == 1"
          id="acordeon-6"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-credit-card />
          </div>
        </b-collapse>
      </div>
      <!-- Medio de pago ContraEntrega -->
      <div class="mb-3">
        <b-card-header
          :style="dataCart.contraentrega == 1 ? '' : 'opacity: .6'"
          :title="
            dataCart.contraentrega == 1
              ? 'Pago contra entrega'
              : 'Medio de pago desactivado'
          "
          class="header-acordeon-payment-method"
          header-tag="header"
          role="tab"
        >
          <div v-b-toggle.acordeon-8 block class="container-header-acordeon">
            <div class="img-header-acordeon">
              <img src="@/assets/icons/tipoPago/icon-contra-entrega.svg" />
            </div>
            <p class="text-header-acordeon">
              <b>Contra entrega</b>
              <span
                style="background-color: #C358FF; border-radius: 5px; padding:.3rem; font-size: 9px; color: white; margin-left: .5rem;"
              >
                <b>Muy pronto</b>
              </span>
              <br />
              <span style="font-size: 14px">
                Paga al momento de recibir
              </span>
            </p>
            <img
              src="@/assets/icons/arrow-simple.svg"
              class="img-arrow-indicator"
            />
          </div>
        </b-card-header>
        <b-collapse
          v-if="dataCart.contraentrega == 1"
          id="acordeon-8"
          class="acordeon-checkout-payment"
          accordion="payment-methods"
          role="tabpanel"
        >
          <div class="component-payment">
            <payment-contra-entrega />
          </div>
        </b-collapse>
      </div>
    </div>
    <b-modal
      id="modal-payment-proccess"
      ref="modal-payment-process"
      v-model="modalPaymentProcess"
      centered
      hide-footer
      hide-header
    >
      <div class="text-center">
        <h5 class="mb-3">
          <b>Hemos identificado una transacción en proceso</b>
        </h5>
        <p class="mt-0 mb-4">
          Es necesario esperar el resultado de esta transacción, antes de volver
          a intentar con otro medio de pago.
        </p>
        <button
          class="col-12 col-md-6 bnt-primary"
          @click="modalPaymentProcess = false"
        >
          Entendido
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-error-406"
      centered
      hide-header
      no-close-on-backdrop
      hide-footer
    >
      <div class="text-center p-4">
        <p class="mx-4 mx-md-5 mb-4">{{ msgModalExcepcion406 }}</p>
        <div class="d-flex justify-content-center">
          <button
            v-if="btnModalExcepcion406 == 'bonoRegalo'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="goPayBono()"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'controEntrega'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="PaymentUponDelibery({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'efectyBaloto'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="PaymentEfectyBaloto({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'pse'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="payPSE({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'creditCard'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="paymentCreditcard({ token: $route.params.tokenCart })"
          >
            Continuar
          </button>
          <button
            v-if="btnModalExcepcion406 == 'cupo'"
            style="min-width: 150px"
            class="btn-primary col-6 mx-2"
            @click="reSendCreditCupo += 1"
          >
            Continuar
          </button>
          <button
            style="min-width: 150px"
            class="secondary-button col-6 mx-2"
            @click="reloadPage()"
          >
            Cancelar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BCardHeader } from 'bootstrap-vue'
import PaymentMethodCupo from '@/components/checkoutAnonimo/metodosPago/PaymentMethodCupo.vue'
import PaymentCreditCard from '@/components/checkoutAnonimo/metodosPago/PaymentCreditCard.vue'
import PaymentPSE from '@/components/checkoutAnonimo/metodosPago/PaymentPSE.vue'
import PaymentEfectyBaloto from '@/components/checkoutAnonimo/metodosPago/PaymentEfectyBaloto.vue'
import PaymentContraEntrega from '@/components/checkoutAnonimo/metodosPago/PaymentContraEntrega.vue'
import PaymentBancolombia from '@/components/checkoutAnonimo/metodosPago/PaymentBancolombia.vue'
import PaymentAddi from '@/components/checkoutAnonimo/metodosPago/PaymentAddi.vue'
import PaymentSistecredito from '@/components/checkoutAnonimo/metodosPago/PaymentSistecredito.vue'

import { mapState, mapActions } from 'vuex'
export default {
  components: {
    BCardHeader,
    PaymentMethodCupo,
    PaymentCreditCard,
    PaymentPSE,
    PaymentEfectyBaloto,
    PaymentContraEntrega,
    PaymentBancolombia,
    PaymentAddi,
    PaymentSistecredito,
  },
  data() {
    return {
      modalPaymentProcess: false,
      showBonoRegalo: false,
      bonoRegalo: '',
      reSendBono: false,
      reSendContraentrega: false,
      reSendBaloto: false,
      reSendPSE: false,
      reSendCreditCard: false,
      reSendCupo: false,
      esFELocal: false,
    }
  },
  computed: {
    ...mapState('cartAnonimo', [
      'dataCart',
      'btnModalExcepcion406',
      'errorAddCuponCard',
      'msgErrorAddCuponCard',
      'msgModalExcepcion406',
    ]),
  },
  watch: {
    esFELocal() {
      this.changeEsFE(this.esFELocal)
    },
    dataCart() {
      if (this.dataCart.carrito_bloqueado == 1) {
        this.modalPaymentProcess = true
      }
    },
  },
  mounted() {
    if (this.dataCart.carrito_bloqueado == 1) {
      this.modalPaymentProcess = true
    }
  },
  methods: {
    ...mapActions('cartAnonimo', [
      'addGiftCard',
      'removeGiftCard',
      'changeEsFE',
      'payWhitGifCard',
    ]),
    reloadPage() {
      location.reload()
    },
    sendBonoRegalo() {
      if (this.bonoRegalo.length > 3) {
        this.addGiftCard({ codigo: this.bonoRegalo })
      }
    },
    async payGifCard() {
      await this.payWhitGifCard()
    },
  },
}
</script>
